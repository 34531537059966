// modules
import { IAppFromServerProps } from "@todesktop/shared";
import { Button, Input, message, Modal, notification, Typography } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { createNewApp } from "../../pages/NewApp";
import { useStore } from "../../store";
import {
  addAppFromServer,
  createCustomerCheckoutSession,
  generateAccessToken,
  updateUIState,
} from "../../~reusables/actions";
import { hrefLinks } from "../../~reusables/constants";
import {
  ThemeContextProvider,
  useTheme,
} from "../../~reusables/contexts/ThemeContext";
import blankLogo from "../../~reusables/images/blank-logo.png";
import { Flex } from "../atoms/Primitives";
import { RevealableText } from "../atoms/TextUtils";
import { CLIPlans } from "../build/SubscribeModal";

type State = {
  open: boolean;
  loading: boolean;
  isElectron: boolean;
};

const { Text } = Typography;

export const CreateAppModal: React.FC = () => {
  const history = useHistory();
  const { colors } = useTheme();
  const accessToken = useStore((state) => state.user?.accessToken);
  const apps = useStore((state) => state.apps);

  const [electronAppName, setElectronAppName] = useState("");
  const [state, setState] = useState<State>({
    open: true,
    loading: false,
    isElectron: !apps.length || apps.some((app) => app.appType === "electron"),
  });
  const onStateChange = (newState: Partial<State>) => {
    setState((state) => ({ ...state, ...newState }));
  };

  const loadCreatedApp = async (priceId: string, app: IAppFromServerProps) => {
    try {
      await addAppFromServer(app);

      const url = `${window.location.origin}/apps/${app.id}`;
      await createCustomerCheckoutSession({
        priceId,
        appId: app.id,
        successUrl: url,
        cancelUrl: url,
      });

      history.push(`/apps/${app.id}`);
    } catch (err) {
      message.error("We had trouble completing this action. Please try again.");
    } finally {
      updateUIState(undefined);
    }
  };

  const onCancel = () => {
    onStateChange({ open: false });
    updateUIState(undefined);
  };

  return (
    <Modal
      open={state.open}
      width={676}
      closable={true}
      centered={true}
      onCancel={onCancel}
      title={
        <Flex css={{ gap: "8px", alignItems: "top" }}>
          <img src={blankLogo} width="42px" height="42px" />

          {state.isElectron ? (
            <Flex css={{ flexDirection: "column" }}>
              <span>Create Electron Application</span>
              <Text type="secondary" style={{ fontSize: 14 }}>
                Begin your 7 day free trial and streamline your Electron
                infrastructure
              </Text>
            </Flex>
          ) : (
            <Flex css={{ flexDirection: "column" }}>
              <span>Download ToDesktop Builder</span>
              <Text type="secondary" style={{ fontSize: 14 }}>
                Bootstrap a desktop app straight from your web app
              </Text>
            </Flex>
          )}
        </Flex>
      }
      footer={
        state.isElectron ? null : (
          <Flex style={{ justifyContent: "space-between" }}>
            <Button
              type="link"
              onClick={() => onStateChange({ isElectron: true })}
            >
              Create Electron app instead
            </Button>
            <Flex>
              <Button>Cancel</Button>
              <Button
                href="https://builder.todesktop.com"
                type="primary"
                onClick={onCancel}
              >
                Download
              </Button>
            </Flex>
          </Flex>
        )
      }
    >
      {state.isElectron ? (
        <Flex
          css={{ flexDirection: "column", color: colors.text, gap: "16px" }}
        >
          <Input
            value={electronAppName}
            onChange={(e) => setElectronAppName(e.target.value)}
            placeholder="Application name"
            autoFocus
          />
          <CLIPlans
            featureLimit={5}
            onSelectPrice={async (priceId) => {
              if (!electronAppName) {
                return message.error("App name is required");
              }

              try {
                onStateChange({ loading: true });
                const { data } = await createNewApp({
                  name: electronAppName,
                  appType: "electron",
                });

                if (!accessToken) {
                  const token = await generateAccessToken();
                  notification.open({
                    duration: 0,
                    message: "Access token generated",
                    description: (
                      <ThemeContextProvider>
                        <Text type="secondary">
                          An access token of{" "}
                          <RevealableText revealText={token} /> has been
                          generated for use with the ToDesktop CLI.{" "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={hrefLinks.cliNpmDocs}
                          >
                            Learn more here.
                          </a>
                        </Text>
                      </ThemeContextProvider>
                    ),
                  });
                }

                await loadCreatedApp(priceId, data);
              } catch (err) {
                const { message: msg, details: app } = err;
                if (app && app.id) {
                  Modal.confirm({
                    title: msg,
                    content: (
                      <>Are you sure you want to continue creating this app?</>
                    ),
                    okText: "Yes, continue",
                    cancelText: "No, change the app",
                    onOk: () => loadCreatedApp(priceId, app),
                    onCancel: () => onStateChange({ loading: false }),
                  });
                } else {
                  onStateChange({ loading: false });
                  message.error(
                    "We had trouble completing this action. Please try again."
                  );
                }
              }
            }}
          />
          <Button
            type="link"
            onClick={() => onStateChange({ isElectron: false })}
          >
            Create no-code app instead
          </Button>
        </Flex>
      ) : (
        <Flex
          css={{ flexDirection: "column", gap: "16px", color: colors.text }}
        >
          <Text>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.todesktop.com/"
            >
              ToDesktop Builder
            </a>{" "}
            is suitable for those who have an existing web app and want to
            transform it into a cross-platform desktop app using a no-code
            interface. Here are some feature highlights:
          </Text>
          <ul>
            <li>
              Bootstrap a desktop app straight <b>from your web app</b>.
            </li>
            <li>
              Optionally add code to customize your app with{" "}
              <b>native features</b>.
            </li>
            <li>
              <b>One-click publish</b> your desktop app to your customers.
            </li>
          </ul>
        </Flex>
      )}
    </Modal>
  );
};
