import React, { useState } from "react";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Dropdown, Menu, Modal, Tooltip } from "antd";
import {
  CopyOutlined,
  UserOutlined,
  LinkOutlined,
  QuestionCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { useStore, selectedApp, appHasActiveSubscription } from "../../store";
import SubscriptionStatusLabel from "../atoms/SubscriptionStatusLabel";
import { deleteApp, goToManageSubscriptions } from "../../~reusables/actions";
import { copyToClipboard, openExternalLink } from "../../~reusables/util";
import { getDownloadMeta } from "../../~reusables/util/urls";
import { hrefLinks } from "../../~reusables/constants";
import DownloadButtonBuilder from "./DownloadButtonBuilder";
import { selectedReleasedBuild } from "../../~reusables/actions/builds";
import { useAppOwnerGuard } from "../../~reusables/hooks/useAppOwnerGuard";

const MenuTitle = styled.span`
  color: #777;
  font-size: 0.7rem;
`;

interface IAppDropdownMenuProps extends RouteComponentProps {
  children: React.ReactNode;
}

const AppDropdownMenu: React.SFC<IAppDropdownMenuProps> = ({ children }) => {
  const [showBtnBuilder, setShowButtonBuilder] = useState(false);
  const app = useStore(selectedApp);
  const hasActiveSubscription = useStore(appHasActiveSubscription);
  const user = useStore((state) => state.user);
  const releasedBuild = useStore(selectedReleasedBuild);
  const { isOwner, ownerGuard } = useAppOwnerGuard();

  const hasMultipleApps = useStore(
    (state) => state.apps && state.apps.length > 1
  );
  if (!app) {
    return null;
  }
  const {
    id,
    meta,
    appType,
    shouldCreateAppImages,
    shouldCreateAppXFiles,
    shouldCreateDMGs,
    shouldCreateAppleSiliconAssets,
    shouldCreateAppleIntelArtifacts,
    shouldCreateDebianPackages,
    shouldCreateMSIInstallers,
    shouldCreateMacAppStoreFiles,
    shouldCreateMacPKG,
    shouldCreateMacZipInstallers,
    shouldCreateMacUniversalInstaller,
    shouldCreateNSISInstallers,
    shouldCreateNSISWebInstaller,
    shouldCreateRPMPackages,
    shouldCreateSnapFiles,
  } = app;
  const { publishedVersions } = meta;
  const version = publishedVersions && publishedVersions.version;
  const electronVersion = publishedVersions && publishedVersions.electron;
  const desktopifyVersion = publishedVersions && publishedVersions.desktopify;

  const downloads = [
    getDownloadMeta({
      wasArtifactCreated: true,
      platform: "universal",
      app,
      releasedBuild,
      label: "Universal URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateMacUniversalInstaller,
      arch: "universal",
      artifactName: "installer",
      platform: "mac",
      app,
      releasedBuild,
      label: "Mac Universal Installer",
    }),
    getDownloadMeta({
      wasArtifactCreated:
        shouldCreateDMGs && shouldCreateAppleIntelArtifacts !== false,
      arch: "x64",
      artifactName: "dmg",
      platform: "mac",
      app,
      releasedBuild,
      label: "Mac DMG URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateDMGs && shouldCreateAppleSiliconAssets,
      arch: "arm64",
      artifactName: "dmg",
      platform: "mac",
      app,
      releasedBuild,
      label: "Mac Apple Silicon DMG URL",
    }),
    getDownloadMeta({
      wasArtifactCreated:
        shouldCreateMacZipInstallers &&
        shouldCreateAppleIntelArtifacts !== false,
      arch: "x64",
      artifactName: "zip",
      platform: "mac",
      app,
      releasedBuild,
      label: "Mac ZIP URL",
    }),
    getDownloadMeta({
      wasArtifactCreated:
        shouldCreateMacZipInstallers && shouldCreateAppleSiliconAssets,
      arch: "arm64",
      artifactName: "zip",
      platform: "mac",
      app,
      releasedBuild,
      label: "Mac Apple Silicon ZIP URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateMacAppStoreFiles,
      arch: "universal",
      artifactName: "mas",
      platform: "mac",
      app,
      releasedBuild,
      label: "Mac App Store URL",
    }),
    getDownloadMeta({
      wasArtifactCreated:
        shouldCreateMacPKG && shouldCreateAppleIntelArtifacts !== false,
      arch: "x64",
      artifactName: "pkg",
      platform: "mac",
      app,
      releasedBuild,
      label: "Mac Intel PKG URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateMacPKG && shouldCreateAppleSiliconAssets,
      arch: "arm64",
      artifactName: "pkg",
      platform: "mac",
      app,
      releasedBuild,
      label: "Mac Apple Silicon PKG URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateNSISInstallers,
      arch: "x64",
      artifactName: "nsis",
      platform: "windows",
      app,
      releasedBuild,
      label: "Windows NSIS URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateNSISWebInstaller,
      arch: "x64",
      artifactName: "nsis-web",
      platform: "windows",
      app,
      releasedBuild,
      label: "Windows NSIS Web URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateMSIInstallers,
      arch: "x64",
      artifactName: "msi",
      platform: "windows",
      app,
      releasedBuild,
      label: "Windows MSI URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateAppXFiles,
      arch: "x64",
      artifactName: "appx",
      platform: "windows",
      app,
      releasedBuild,
      label: "Windows AppX (app store) URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateAppImages,
      arch: "x64",
      artifactName: "appImage",
      platform: "linux",
      app,
      releasedBuild,
      label: "Linux AppImage URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateDebianPackages,
      arch: "x64",
      artifactName: "deb",
      platform: "linux",
      app,
      releasedBuild,
      label: "Linux Debian URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateRPMPackages,
      arch: "x64",
      artifactName: "rpm",
      platform: "linux",
      app,
      releasedBuild,
      label: "Linux RPM URL",
    }),
    getDownloadMeta({
      wasArtifactCreated: shouldCreateSnapFiles,
      arch: "x64",
      artifactName: "snap",
      platform: "linux",
      app,
      releasedBuild,
      label: "Linux Snap URL",
    }),
  ];

  const isDeletable = hasMultipleApps && !hasActiveSubscription && isOwner;

  const toggleButtonBuilder = () => setShowButtonBuilder(!showBtnBuilder);

  const menu = (
    <Menu>
      {id && (
        <Menu.Item
          title="Copy ID to clipboard"
          onClick={copyToClipboard("id", id)}
        >
          <CopyOutlined />
          <MenuTitle>id:</MenuTitle> {id}
        </Menu.Item>
      )}

      {hasActiveSubscription && (
        <Menu.Item
          title="Manage subscriptions"
          onClick={goToManageSubscriptions}
        >
          <UserOutlined />
          <MenuTitle>status:</MenuTitle>{" "}
          <SubscriptionStatusLabel
            labelCss={css`
              cursor: pointer;
            `}
            planId={app.subscription ? app.subscription.planId : ""}
            status={app.subscription ? app.subscription.status : ""}
            showTitle={false}
          />
        </Menu.Item>
      )}

      <Menu.Divider />

      {appType === "electron" && (
        <Menu.SubMenu title="Documentation">
          <Menu.ItemGroup title="Libraries">
            <Menu.Item onClick={openExternalLink(hrefLinks.cliNpmDocs)}>
              CLI documentation
            </Menu.Item>
            <Menu.Item onClick={openExternalLink(hrefLinks.runtimeNpmDocs)}>
              Runtime documentation
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Migration Guides">
            <Menu.Item
              onClick={openExternalLink(hrefLinks.migrationGuides.general)}
            >
              <LinkOutlined />
              General
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(
                hrefLinks.migrationGuides.electronBuilder
              )}
            >
              <LinkOutlined />
              electron-builder
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(
                hrefLinks.migrationGuides.electronWebpack
              )}
            >
              <LinkOutlined />
              electron-webpack
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(
                hrefLinks.migrationGuides.electronForge
              )}
            >
              <LinkOutlined />
              electron-forge
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu.SubMenu>
      )}

      {appType === "app" && (
        <Menu.SubMenu title="Documentation">
          <Menu.Item
            onClick={openExternalLink(hrefLinks.documentationOverview)}
          >
            Documentation Overview
          </Menu.Item>
          <Menu.ItemGroup title="App Options">
            <Menu.Item
              onClick={openExternalLink(hrefLinks.optionsLaunchAtStartup)}
            >
              <LinkOutlined />
              Launch at Startup by Default
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(hrefLinks.optionsDefiningInternalUrls)}
            >
              <LinkOutlined />
              Defining Internal URLs
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(hrefLinks.optionsAppProtocols)}
            >
              <LinkOutlined />
              App Protocols &amp; Deeplinks
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Customizing Your App">
            <Menu.Item onClick={openExternalLink(hrefLinks.customizeCustomCSS)}>
              <LinkOutlined />
              Adding CSS
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(hrefLinks.customizeCustomJavascript)}
            >
              <LinkOutlined />
              Adding JS
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(hrefLinks.customizeTransparentTitlebar)}
            >
              <LinkOutlined />
              Make transparent titlebar draggable
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Javascript API">
            <Menu.Item onClick={openExternalLink(hrefLinks.apiInfo)}>
              <LinkOutlined />
              Info
            </Menu.Item>
            <Menu.Item onClick={openExternalLink(hrefLinks.apiBadges)}>
              <LinkOutlined />
              Badging, Bouncing &amp; Progress Bar
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Recipes">
            <Menu.Item
              onClick={openExternalLink(hrefLinks.recipesNotifications)}
            >
              <LinkOutlined />
              Sending Notifications
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(hrefLinks.recipesSeparatingLogic)}
            >
              <LinkOutlined />
              Separating Desktop Logic
            </Menu.Item>
            <Menu.Item onClick={openExternalLink(hrefLinks.recipesStoringData)}>
              <LinkOutlined />
              Storing Data
            </Menu.Item>
            <Menu.Item
              onClick={openExternalLink(hrefLinks.recipesDownloadLinks)}
            >
              <LinkOutlined />
              Download Links for your website
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu.SubMenu>
      )}

      {hasActiveSubscription && (
        <Menu.Item
          title="Create a Download Button for your app"
          onClick={toggleButtonBuilder}
        >
          Create a Download Button for your app
        </Menu.Item>
      )}

      {hasActiveSubscription && (
        <Menu.SubMenu title={`${app.name} App Download Links`}>
          {downloads.map(({ downloadUrl, label, shouldShow, icon: Icon }) => {
            return (
              shouldShow && (
                <Menu.Item
                  key={label}
                  title={`Copy ${label} to clipboard`}
                  onClick={copyToClipboard(label, downloadUrl)}
                >
                  <Icon /> {label}
                </Menu.Item>
              )
            );
          })}
          <Menu.Divider />
          <Menu.Item
            title="Download Link Documentation"
            onClick={openExternalLink(hrefLinks.recipesDownloadLinks)}
          >
            <QuestionCircleFilled /> More Info…
          </Menu.Item>
        </Menu.SubMenu>
      )}

      {version && (
        <Menu.SubMenu title="Versions">
          <Menu.Item
            title={`Copy ${app.name} app version to clipboard`}
            onClick={copyToClipboard(`${app.name} app version`, version)}
          >
            <CopyOutlined />
            <MenuTitle>app:</MenuTitle> {version}
          </Menu.Item>

          {electronVersion && (
            <Menu.Item
              title="Copy Electron version to clipboard"
              onClick={copyToClipboard("Electron version", electronVersion)}
            >
              <CopyOutlined />
              <MenuTitle>electron:</MenuTitle> {electronVersion}
            </Menu.Item>
          )}

          {desktopifyVersion && (
            <Menu.Item
              title="Copy ToDesktop runtime library version to clipboard"
              onClick={copyToClipboard(
                "ToDesktop runtime library version",
                desktopifyVersion
              )}
            >
              <CopyOutlined />
              <MenuTitle>runtime:</MenuTitle> {desktopifyVersion}
            </Menu.Item>
          )}
        </Menu.SubMenu>
      )}

      {user && (
        <Menu.Item
          style={{ cursor: isDeletable ? "pointer" : "not-allowed" }}
          onClick={() => {
            if (isDeletable) {
              Modal.confirm({
                title: "Are you sure you want to delete this app?",
                content: "This action can not be undone.",
                onOk() {
                  deleteApp(user, app);
                },
                okText: "Yes, Delete",
                okType: "danger",
                cancelText: "No",
              });
            }
          }}
        >
          <Tooltip
            title={
              isOwner
                ? ownerGuard
                : isDeletable
                ? ""
                : "Cannot delete an app with an active subscription"
            }
          >
            <span
              css={css`
                min-width: 12px;
                margin-right: 8px;
                color: ${isDeletable ? "#f5222d" : "rgba(0, 0, 0, 0.25"};
              `}
            >
              <DeleteOutlined />
            </span>
            <span
              css={css`
                color: ${isDeletable ? "#f5222d" : "rgba(0, 0, 0, 0.25"};
              `}
            >
              Delete app
            </span>
          </Tooltip>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      {hasActiveSubscription && (
        <Modal
          title="Create a Download Button for your Web App"
          centered
          footer={null}
          visible={showBtnBuilder}
          onCancel={toggleButtonBuilder}
          closable={true}
        >
          <DownloadButtonBuilder app={app} />
        </Modal>
      )}
      <Dropdown trigger={["click"]} overlay={menu}>
        {children}
      </Dropdown>
    </>
  );
};

export default withRouter(AppDropdownMenu);
