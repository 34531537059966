import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { Product, products } from "@todesktop/shared";
import { Button, Divider, Modal, Popover, Switch, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { selectedApp, useStore } from "../../store";
import {
  createCustomerCheckoutSession,
  removeUIState,
  updateUIState,
} from "../../~reusables/actions";
import { stage } from "../../~reusables/firebase/config";
import { history } from "../../~reusables/util";
import { Flex } from "../atoms/Primitives";

const { Title, Text } = Typography;

export const SubscribeModal: React.FC = () => {
  const app = useStore(selectedApp);
  const { uiState, user } = useStore((state) => ({
    uiState: state.uiState,
    user: state.user,
  }));

  useEffect(() => {
    if (user) return;
    updateUIState("convert-login");
  }, [user]);

  return (
    <Modal
      open={uiState === "subscribe"}
      width={676}
      footer={null}
      closable={true}
      centered={true}
      onCancel={() => {
        if (app.appType === "electron") {
          Modal.confirm({
            title: "Closing this modal will redirect you back home",
            onOk() {
              history.push("/");
              removeUIState();
            },
          });
        } else {
          removeUIState();
        }
      }}
    >
      {app?.appType === "electron" && (
        <CLIPlans
          onSelectPrice={async (priceId) => {
            await createCustomerCheckoutSession({
              priceId,
              appId: app.id,
            });
          }}
        >
          <Title level={3}>Begin 7 Day Free Trial</Title>
        </CLIPlans>
      )}
    </Modal>
  );
};

export const CLIPlans: React.FC<{
  filteredProductIds?: string[];
  featureLimit?: number;
  onSelectPrice: (priceId: string) => Promise<void>;
  onViewOtherPlans?: () => void;
}> = ({
  children,
  filteredProductIds,
  featureLimit,
  onSelectPrice,
  onViewOtherPlans,
}) => {
  return (
    <Plans
      filteredProductIds={filteredProductIds}
      featureLimit={featureLimit}
      defaultProductId={products[stage].cli.performance.id}
      products={[
        {
          label: "Essential",
          product: products[stage].cli.founder,
          features: [
            {
              label: (
                <>
                  <strong>35</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>30 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>5000</strong> downloads/month
                </>
              ),
            },
            { label: "Windows, Mac & Linux build servers" },
            { label: "Code signing" },
            { label: "Auto updates" },
            { label: "Native app installers" },
            { label: "Native module rebuilding" },
            { label: "Automated Electron code analysis" },
            { label: "CDN-backed downloads/updates" },
            { label: "Download links on your domain" },
          ],
        },
        {
          label: "Performance",
          product: products[stage].cli.performance,
          features: [
            {
              label: (
                <>
                  <strong>120</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>60 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>15,000</strong> downloads/month
                </>
              ),
            },
            { label: "Staging and dev apps" },
            { label: "Auto-update smoke testing" },
            { label: "Windows & Mac web installers" },
            { label: "Private Slack-channel support" },
            { label: "MSI and PKG installers" },
            { label: "Microsoft and Mac app store" },
            { label: "Download analytics" },
            { label: "Build analytics" },
          ],
        },
        {
          label: "Scale",
          product: products[stage].cli.scale,
          features: [
            {
              label: (
                <>
                  <strong>1,000</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>90 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>60,000</strong> downloads/month
                </>
              ),
            },
            { label: "Support for staggered rollouts" },
            { label: "Account manager" },
            { label: "Priority support & onboarding" },
            { label: "Integration assistance" },
          ],
        },
      ]}
      onSelectPrice={onSelectPrice}
      onViewOtherPlans={onViewOtherPlans}
    >
      {children}
    </Plans>
  );
};

interface PlansProps {
  defaultProductId: string;
  filteredProductIds?: string[];
  products: {
    label: string;
    product: Product;
    features: { label: React.ReactNode }[];
  }[];
  featureLimit?: number;
  onSelectPrice: (priceId: string) => Promise<void>;
  onViewOtherPlans?: () => void;
}

interface PlanState {
  loading: boolean;
  filteredProductIds: string[];
  selectedProductId: string;
  period: "monthly" | "yearly";
}

const Plans: React.FC<PlansProps> = ({
  filteredProductIds = [],
  defaultProductId,
  products,
  featureLimit,
  onSelectPrice,
  onViewOtherPlans,
  children,
}) => {
  const [state, setState] = useState<PlanState>({
    selectedProductId: defaultProductId,
    filteredProductIds,
    loading: false,
    period: "yearly",
  });

  const filteredProducts = products.filter(({ product }) => {
    if (!state.filteredProductIds.length) return true;
    return state.filteredProductIds.includes(product.id);
  });

  const displayedProducts = filteredProducts.length
    ? filteredProducts
    : products;

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {children}
      <Flex css={{ gap: "12px", width: "100%" }}>
        {displayedProducts.map(({ label, product, features }, i) => {
          const price = Object.values(product.prices).find(
            (price) =>
              price.status === "active" && price.period === state.period
          );

          const isYearly = state.period === "yearly";
          const amount = isYearly ? price.amount / 12 : price.amount;
          const previousProduct = products[i - 1];

          const limitedFeatures = features.slice(0, featureLimit);
          const tooltipFeatures = features.slice(limitedFeatures.length);

          return (
            <Flex
              key={product.id}
              css={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "16px",
                borderRadius: "4px",
                background: product.id === defaultProductId ? "#F8F8F8" : "",
              }}
            >
              <Flex css={{ flexDirection: "column" }}>
                <Title level={5} style={{ marginBottom: 4 }}>
                  {label}
                </Title>
                <Flex
                  css={{ alignItems: "center", gap: "6px", flexWrap: "wrap" }}
                >
                  <Text>${amount}/month</Text>
                  {isYearly && (
                    <Tag
                      color="green"
                      style={{ border: "0", fontWeight: "bold" }}
                    >
                      -20%
                    </Tag>
                  )}
                </Flex>
                <Flex
                  css={{
                    paddingTop: "8px",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Switch
                    size="small"
                    checked={isYearly}
                    onChange={(checked) => {
                      setState((prev) => ({
                        ...prev,
                        period: checked ? "yearly" : "monthly",
                      }));
                    }}
                  />
                  <Text type="secondary">Annual</Text>
                </Flex>
                <Divider />
                <Flex css={{ flexDirection: "column", gap: "6px" }}>
                  <Text type="secondary" style={{ fontSize: "11px" }}>
                    {previousProduct
                      ? `Everything in ${previousProduct.label}, plus:`
                      : `${label} plan includes:`}
                  </Text>
                  {limitedFeatures.map(({ label }, i) => {
                    return <FeatureCheck key={i}>{label}</FeatureCheck>;
                  })}
                  {tooltipFeatures.length ? (
                    <Popover
                      placement="bottomLeft"
                      content={
                        <Flex css={{ flexDirection: "column", gap: "6px" }}>
                          {tooltipFeatures.map(({ label }, i) => {
                            return <FeatureCheck key={i}>{label}</FeatureCheck>;
                          })}
                        </Flex>
                      }
                    >
                      <Flex
                        css={{
                          alignItems: "baseline",
                          gap: "6px",
                          cursor: "default",
                        }}
                      >
                        <DownOutlined style={{ fontSize: 10 }} />
                        <Text underline style={{ fontSize: 12 }}>
                          {tooltipFeatures.length} more features
                        </Text>
                      </Flex>
                    </Popover>
                  ) : null}
                </Flex>
              </Flex>
              <Flex css={{ flexDirection: "column" }}>
                <Divider />
                <Button
                  type="primary"
                  shape="round"
                  disabled={state.loading}
                  loading={
                    state.loading && state.selectedProductId === product.id
                  }
                  onClick={async () => {
                    setState((prev) => ({
                      ...prev,
                      selectedProductId: product.id,
                      loading: true,
                    }));

                    await onSelectPrice(price.id);
                    setState((prev) => ({ ...prev, loading: false }));
                  }}
                >
                  Start trial
                </Button>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      {displayedProducts.length !== products.length ? (
        <Button
          type="text"
          onClick={() => {
            onViewOtherPlans?.();
            setState((state) => ({ ...state, filteredProductIds: [] }));
          }}
        >
          View other plans
        </Button>
      ) : null}
    </Flex>
  );
};

const FeatureCheck: React.FC = ({ children }) => {
  return (
    <Flex css={{ alignItems: "baseline", gap: "6px" }}>
      <CheckOutlined style={{ fontSize: 10 }} />
      <Text style={{ fontSize: 12 }}>{children}</Text>
    </Flex>
  );
};
