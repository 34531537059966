import { Button, Form, Skeleton, Typography, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { selectedApp, useStore } from "../../../store";
import {
  isFileChangeError,
  toBase64,
  updateMacCert,
} from "../../../~reusables/actions";
import { callFirebaseFunction } from "../../../~reusables/firebase";
import useForm from "../../../~reusables/hooks/useForm";
import { CertTabs, IHSMCertificate } from "../../../~reusables/types";
import { certificateValidation } from "../../../~reusables/util/validationRules";
import { FormInput, FormItem } from "../../atoms/FormUtils";
import { Flex } from "../../atoms/Primitives";
import {
  CertificateChangeWarning,
  TablessCertPane,
  genFormEvent,
} from "./SharedCertUtils";
import { MacTarget } from "@todesktop/shared";

const { Text } = Typography;

export interface CertificateProps {
  target: Exclude<MacTarget, "mac">; // mac is handled in MacCertificate.tsx
  label: string;
}

export const Certificate: React.FC<CertificateProps> = ({ target, label }) => {
  const { id, macHsmCertNames } = useStore(selectedApp);

  const [state, setState] = useState<{
    hsmCertDetails: IHSMCertificate | null;
    loading: boolean;
    error: string;
    tab: CertTabs;
  }>({
    hsmCertDetails: null,
    loading: true,
    error: "",
    tab: CertTabs.AddCert,
  });

  const hsmCertName = macHsmCertNames?.[target];
  useEffect(() => {
    // because nothing is being stored in firestore, we need to optimistically make this request for data.
    // For that reason, we won't display an error if it fails to find something.
    getHSMCert({ type: target, appId: id, hsmCertName })
      .then((res) => {
        const hsmCertDetails = res.data;
        setState((prev) => ({
          ...prev,
          hsmCertDetails,
          loading: false,
          tab: CertTabs.ViewCert,
        }));
      })
      .catch((err) => {
        console.warn(err);
        setState((prev) => ({
          ...prev,
          hsmCertDetails: null,
          loading: false,
          tab: CertTabs.AddCert,
        }));
      });
  }, [id, target, hsmCertName]);

  const form = useForm(
    { password: "", pfxBase64: "" },
    async (data) => {
      const { pfxBase64, password } = data;

      try {
        await updateMacCert(id, password, pfxBase64, target);
        message.success("Certificate saved");
        setState((prev) => ({ ...prev, tab: CertTabs.ViewCert }));
      } catch (err) {
        console.warn(err);
        setState((prev) => ({ ...prev, error: err.message }));
      }
    },
    certificateValidation
  );

  useEffect(() => {
    if (form.formError) message.error(form.formError);
  }, [form.formError]);

  if (state.loading) return <Skeleton />;

  return (
    <Flex css={{ flexDirection: "column", gap: "8px" }}>
      <Text style={{ fontWeight: 500 }}>{label}</Text>
      <TablessCertPane
        tabUtils={{
          certTab: state.tab,
          setCertTab: (tab) => {
            setState((prev) => ({ ...prev, tab }));
          },
          isCertInUse: state.hsmCertDetails ? true : false,
        }}
        viewComponentProps={{
          cert: state.hsmCertDetails || { name: "" },
          target,
        }}
        addComponent={
          <Form layout="vertical" size="small" onFinish={form.handleSubmit}>
            <Flex css={{ flexDirection: "column", gap: "10px" }}>
              {state.hsmCertDetails && <CertificateChangeWarning />}
              <FormItem label="" error={state.error || form.errors.pfxBase64}>
                <Upload
                  onChange={({ file }) => {
                    if (!file || isFileChangeError(file)) return;

                    toBase64((file as unknown) as Blob)
                      .then((base64) => {
                        form.handleChange(genFormEvent("pfxBase64", base64));
                      })
                      .catch((err) => message.error(err.message, 3));
                  }}
                  accept=".p12"
                  maxCount={1}
                  beforeUpload={() => false}
                >
                  <Button>Select File</Button>
                </Upload>
              </FormItem>
              <FormInput
                error={form.errors.password}
                label="Certificate Password"
                name="password"
                onChange={form.handleChange}
                value={form.values.password}
                disabled={form.isSubmitting}
                password
              />
            </Flex>

            <Flex justifyContent="end">
              <Button
                htmlType="submit"
                loading={form.isSubmitting}
                type="primary"
              >
                Save
              </Button>
            </Flex>
          </Form>
        }
      />
    </Flex>
  );
};

const getHSMCert = callFirebaseFunction("getHSMCert");
