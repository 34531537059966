// modules
import { AppleFilled, QqOutlined, WindowsFilled } from "@ant-design/icons";
import css from "@emotion/css";
import {
  BuildStatus,
  IApp,
  Build as IBuild,
  PlatformBuild,
  PlatformName,
  isBuildRunning,
} from "@todesktop/shared";
import {
  Button,
  Col,
  Divider,
  Empty,
  Image,
  Input,
  Row,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  CardIsEmpty,
  StyledAntdCard,
} from "../../components/atoms/StyledAntdCard";
import { Text } from "../../components/atoms/Text";
import { DummyAppLoader } from "../../components/elements/DummyAppLoader";
import { BuildOverviewCard } from "../../components/molecules/BuildOverviewCard";
import {
  LinuxLinks,
  MacLinks,
  WindowsLinks,
} from "../../components/molecules/DownloadLinkTabs";
import ViewAzureLog from "../../components/molecules/ViewAzureLog";
import { selectedAppUser, useStore } from "../../store";
import {
  selectBuild,
  selectedReleasedBuild,
} from "../../~reusables/actions/builds";
import { useTheme } from "../../~reusables/contexts/ThemeContext";

import { Box, Flex } from "../../components/atoms/Primitives";
import {
  useDependencyAnalysisFunction,
  useSmokeTestFunction,
  useSmokeTestLogsFunction,
  useStaticAnalysisFunction,
} from "../../~reusables/hooks/useFirebaseFunction";
import { useDelayedValue } from "../../~reusables/hooks/useValue";
import {
  DependencyAnalysisTable,
  ToDesktopJSONDiff,
} from "../routes/release/BuildArtifactsSection";
import { PerformanceMetrics } from "../routes/release/PerformanceMetrics";
import {
  SmokeTestResult,
  getSmokeTestResult,
} from "../routes/release/SmokeTestingSection";
import { StaticAnalysisComponent } from "../routes/release/StaticAnalysisSection";
import { useDependencyAnalysis } from "../routes/release/useBuildArtifactsSection";
import { useSmokeTestingContent } from "../routes/release/useContent";

type IBuilds = RouteComponentProps<{ buildId: string; id: string }>;

const Build: React.FC<IBuilds> = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const { id: appId, buildId } = match.params;
  const app = useStore((state) => state.apps.find((app) => app.id === appId));
  const build = useStore((state) => state.buildState.builds[buildId]);

  useEffect(() => {
    selectBuild(buildId);
    setLoading(false);
  }, [buildId]);

  if (loading) {
    return <DummyAppLoader loading={true} />;
  }

  if (!build || !app) {
    return (
      <StyledAntdCard title="Build not found">
        <Empty>
          <Link to="/">
            <Button type="primary">Back to home</Button>
          </Link>
        </Empty>
      </StyledAntdCard>
    );
  }

  return (
    <>
      <Row gutter={24}>
        <Col lg={18} span={24}>
          <BuildOverviewCard build={build} />
        </Col>
      </Row>

      <Row>
        <Tabs
          style={{ overflow: "visible", minWidth: "100%", width: "100%" }}
          animated={false}
        >
          {[build.mac, build.windows, build.linux].map((platformBuild) => {
            if (!platformBuild || platformBuild.shouldSkip) return null;
            const { platform } = platformBuild;
            if (!platform) {
              return null;
            }

            const Icons: Record<PlatformName, typeof QqOutlined> = {
              mac: AppleFilled,
              windows: WindowsFilled,
              linux: QqOutlined,
            };

            const Icon = Icons[platform];

            return (
              <Tabs.TabPane
                key={platform}
                tab={
                  <>
                    <Icon />
                    {platform[0].toUpperCase() + platform.substring(1)}
                  </>
                }
              >
                <PlatformView
                  app={app}
                  build={build}
                  platform={platform}
                  platformBuild={platformBuild}
                />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Row>
    </>
  );
};

const PlatformView: React.FC<{
  app: IApp;
  build: IBuild;
  platformBuild: PlatformBuild;
  platform: PlatformName;
}> = ({ app, build, platformBuild, platform }) => {
  const buildDone = !isBuildRunning(build);
  const buildSucceeded = platformBuild.status === BuildStatus.succeeded;
  const buildErrored =
    !!platformBuild.logs?.length || !!platformBuild.errorMessage;
  const succeededOnRetry = buildDone && buildSucceeded && buildErrored;

  const { colors } = useTheme();

  const errorMessage = [
    ...(platformBuild.logs || []).map((log) => `${log.label}: ${log.content}`),
    platformBuild.errorMessage,
  ].join("\n");

  return (
    <>
      <Row gutter={24}>
        <Col lg={12} span={24}>
          <StyledAntdCard title="Download Links">
            {platformBuild.status === BuildStatus.succeeded ? (
              <>
                {platform === "mac" && (
                  <MacLinks build={build} platform={platform} />
                )}
                {platform === "windows" && (
                  <WindowsLinks build={build} platform={platform} />
                )}
                {platform === "linux" && (
                  <LinuxLinks build={build} platform={platform} />
                )}
              </>
            ) : (
              <CardIsEmpty>
                <Text color="support">No download links yet</Text>
              </CardIsEmpty>
            )}
          </StyledAntdCard>
        </Col>
        <Col lg={12} span={24}>
          <StyledAntdCard
            title="Error Log"
            css={css`
              .ant-input {
                background-color: ${colors.greys[8]};
              }
            `}
            extra={[
              succeededOnRetry && (
                <Tag color="green" key="succeeded">
                  succeeded on retry
                </Tag>
              ),
              build.buildServerExecutionId && build.ciRunner === "azure" && (
                <ViewAzureLog
                  appId={app.id}
                  buildId={build.id}
                  defaultPlatform={platform}
                  key="azure-log"
                />
              ),
            ].filter(Boolean)}
          >
            <Input.TextArea rows={5} spellCheck={false} value={errorMessage} />
          </StyledAntdCard>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={12} span={24}>
          <SmokeTestCards app={app} build={build} platform={platform} />
        </Col>
        <Col lg={12} span={24}>
          <BuildArtifactsCard app={app} build={build} />
        </Col>
      </Row>
      <StaticAnalysisCard app={app} build={build} />
    </>
  );
};

const BuildArtifactsCard: React.FC<{ app: IApp; build: IBuild }> = ({
  app,
  build,
}) => {
  const user = useStore(selectedAppUser);
  const release = useStore(selectedReleasedBuild);
  const depAnalysisFn = useDependencyAnalysisFunction();
  const { buildStatus, loadingMessage, analysisRows } = useDependencyAnalysis(
    build,
    release
  );

  const disabled = build.dependencyAnalysis ? true : false;

  return (
    <div style={{ display: "inline-block", width: "100%" }}>
      <StyledAntdCard
        title="Build artifacts"
        extra={
          <Button
            size="small"
            loading={depAnalysisFn.state === "calling"}
            disabled={disabled}
            onClick={() => {
              depAnalysisFn
                .callFn({ appId: app.id, buildId: build.id, userId: user?.id })
                .catch(console.error);
            }}
          >
            Run build analysis
          </Button>
        }
      >
        <DependencyAnalysisTable
          app={app}
          build={build}
          release={release}
          dataSource={loadingMessage ? [] : analysisRows}
          loading={
            buildStatus && loadingMessage ? { tip: loadingMessage } : false
          }
        />
        <ToDesktopJSONDiff build={build} release={release} />
      </StyledAntdCard>
    </div>
  );
};

const StaticAnalysisCard: React.FC<{ app: IApp; build: IBuild }> = ({
  app,
  build,
}) => {
  const user = useStore(selectedAppUser);
  const staticAnalysisFn = useStaticAnalysisFunction();
  const { staticAnalysis } = build;

  const disabled = staticAnalysis && !staticAnalysis.error ? true : false;

  return (
    <div style={{ display: "inline-block", width: "100%" }}>
      <StyledAntdCard
        title="Static analysis"
        extra={
          <Button
            size="small"
            loading={staticAnalysisFn.state === "calling"}
            disabled={disabled}
            onClick={() => {
              staticAnalysisFn
                .callFn({ appId: app.id, buildId: build.id, userId: user?.id })
                .catch(console.error);
            }}
          >
            Run static analysis
          </Button>
        }
      >
        <StaticAnalysisComponent app={app} build={build} />
      </StyledAntdCard>
    </div>
  );
};

const SmokeTestCards: React.FC<{
  app: IApp;
  build: IBuild;
  platform: PlatformName;
}> = ({ app, build, platform }) => {
  const user = useStore(selectedAppUser);
  const smokeTestCall = useSmokeTestFunction();
  const smokeTestLogsCall = useSmokeTestLogsFunction();
  const smokeTest = build.smokeTest?.[platform];

  const hasScreenshot = Boolean(smokeTest?.screenshot);
  const hasPerformance = Boolean(smokeTest?.performance?.bc);
  const smokeTestResult = getSmokeTestResult(
    "lazy",
    platform,
    build,
    smokeTestCall,
    smokeTestLogsCall,
    useSmokeTestingContent()
  );

  const smokeTestsCompleted = useDelayedValue(
    [
      build.smokeTest?.mac,
      build.smokeTest?.windows,
      build.smokeTest?.linux,
    ].every((test) => ["done", "error", "skipped"].includes(test?.state)),
    15000
  );

  useEffect(() => {
    if (!smokeTestsCompleted) return;
    smokeTestLogsCall
      .callFn({ appId: app.id, buildId: build.id })
      .catch(console.error);
  }, [smokeTestsCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {build.todesktopRuntimeVersionSpecified && (
        <div style={{ display: "inline-block", width: "100%" }}>
          <StyledAntdCard
            title="Smoke tests"
            bodyStyle={{ padding: 0 }}
            extra={
              <Button
                size="small"
                loading={smokeTestCall.state === "calling"}
                disabled={[
                  build.smokeTest?.mac,
                  build.smokeTest?.windows,
                  build.smokeTest?.linux,
                ].some(
                  (test) => test?.state === "done" || test?.state === "progress"
                )}
                onClick={() => {
                  smokeTestCall
                    .callFn({
                      appId: app.id,
                      buildId: build.id,
                      userId: user?.id,
                    })
                    .catch(console.error);
                }}
              >
                Run Smoke Tests
              </Button>
            }
          >
            {smokeTest ? (
              <SmokeTestResult
                style={{ border: "none" }}
                app={app}
                build={build}
                smokeTest={smokeTest}
                {...smokeTestResult}
              />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </StyledAntdCard>
        </div>
      )}
      {hasScreenshot || hasPerformance ? (
        <div style={{ display: "inline-block", width: "100%" }}>
          <StyledAntdCard title="Smoke test preview">
            <Flex style={{ flexWrap: "wrap", gap: "16px" }}>
              {hasScreenshot && (
                <Box css={{ flex: 3, flexBasis: "400px" }}>
                  <Image
                    src={smokeTest.screenshot}
                    preview={false}
                    style={{ borderRadius: "4px" }}
                  />
                </Box>
              )}
              {hasPerformance && (
                <PerformanceMetrics
                  build={build}
                  status={smokeTestResult.appPerformanceStatus}
                  smokeTestProgress={smokeTest}
                />
              )}
            </Flex>
          </StyledAntdCard>
        </div>
      ) : null}
    </>
  );
};

export default Build;
