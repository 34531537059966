// modules
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import queryString from "query-string";

// components
import ManageSubscriptions from "../account/ManageSubscriptions";
import ManageAccount from "../account/ManageAccount";
import ChoosePackage from "../build/ChoosePackage";
import CreateAccount, { CreateSubWithoutBuild } from "../account/CreateAccount";
import PasswordSignup from "../account/PasswordSignup";
import ResetPasswordUpgradedAccount from "../account/ResetPasswordUpgradedAccount";
import NewApp from "../../pages/NewApp";
import ManageAccessToken from "../modals/ManageAccessToken";

// logic
import { useStore, selectedApp } from "../../store";
import { updateUIState } from "../../~reusables/actions";
import { track } from "../../~reusables/util/analytics";
import LegacyPayModal from "../build/LegacyPayModal";
import { ManageUsers } from "../users/ManageUsers";
import CreateLegacyAppModal from "../modals/new-app-modal/CreateLegacyAppModal";
import { PayToDesktopBuilder } from "../build/PayToDesktopBuilder";
import { SubscribeModal } from "../build/SubscribeModal";
import { products } from "@todesktop/shared";
import { stage } from "../../~reusables/firebase/config";
import { CreateAppModal } from "../modals/CreateAppModal";

type IModalsContainer = RouteComponentProps;

const ModalsContainer: React.FC<IModalsContainer> = (routeProps) => {
  const [priceId, setPriceIdDirect] = useState(
    products[stage].legacy.professional.prices.monthly_199.id
  );
  const { uiState, user, apps } = useStore((state) => ({
    uiState: state.uiState,
    user: state.user,
    selectedTeam: state.selectedTeam,
    apps: state.apps,
  }));

  const app = useStore(selectedApp);

  const setPriceId = (plan: string) => () => {
    setPriceIdDirect(plan);
    updateUIState(user ? "pay" : "convert-login");
    track({ event: "Selected Plan", properties: { plan } });
  };

  useEffect(() => {
    const query = queryString.parse(routeProps.location.search);
    if (user) {
      if (user.stripeCustomerId && query["uistate"] === "manage-billing") {
        /**
         * Allow Stripe Customer to update payment details if their
         * search query contains "/?uistate=manage-billing"
         */
        updateUIState("manage-billing");
      } else if (query["uistate"] === "manage-users") {
        updateUIState("manage-users");
      } else if (query["uistate"] === "new-legacy-app") {
        updateUIState("new-legacy-app");
      }
    }
  }, [routeProps.location.search, user]);

  return (
    <>
      {uiState === "choose-plan" ? (
        <ChoosePackage setPriceId={setPriceId} />
      ) : null}
      {uiState === "pay" ? (
        <LegacyPayModal app={app} priceId={priceId} />
      ) : null}
      {uiState === "subscribe" ? <SubscribeModal /> : null}
      {uiState === "pay-todesktop-builder" ? <PayToDesktopBuilder /> : null}
      {uiState === "convert-login" ? <CreateAccount app={app} /> : null}
      {uiState === "create-sub-without-build" ? (
        <CreateSubWithoutBuild app={app} />
      ) : null}
      {uiState === "reset-password-on-upgraded-account" ? (
        <ResetPasswordUpgradedAccount app={app} />
      ) : null}
      {uiState === "password-signup" ? <PasswordSignup app={app} /> : null}
      {uiState === "new-app" ? <NewApp apps={apps} {...routeProps} /> : null}
      {uiState === "create-new-app" ? <CreateAppModal /> : null}
      {uiState === "new-legacy-app" ? <CreateLegacyAppModal /> : null}
      {uiState === "manage-subscriptions" ? <ManageSubscriptions /> : null}
      {uiState === "manage-account" ? <ManageAccount /> : null}
      {uiState === "manage-users" ? <ManageUsers /> : null}
      {uiState === "manage-access-token" ? <ManageAccessToken /> : null}
    </>
  );
};

export default ModalsContainer;
